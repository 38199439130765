import React, { useEffect } from 'react';
import { Slide } from 'react-awesome-reveal';
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebaseConfig"

import Recommend from '../../layout/Recommend'
import Footer from '../../layout/Footer'

import './otakutalk.css'
import otakutalk1 from '../../../images/otakutalk1.png'
import onnx_icon from '../../../images/onnx_icon.png'
import postgresql_icon from '../../../images/postgre_icon.png'
import opencv_icon from '../../../images/opencv_icon.png'
import google_s2t_icon from '../../../images/google_s2t_icon.png'

const OtakuTalk = () => {

	useEffect(() => {
		logEvent(analytics, 'screen_view', {
			firebase_screen: "OtakuTalk",
			firebase_screen_class: "OtakuTalk"
		});
	}, [])

	const vidWidth = Math.min(Math.floor(window.innerWidth * 0.8), 480);
	const vidHeight = Math.floor(vidWidth * 1598/1080);
	return (
		<div className="overflow-x-hidden">
			<div className="otakutalk-wallpaper overflow-x-hidden">
				<Slide direction='left' duration={1500} triggerOnce={false} className="full-width">
					<div className="otakutalk-title">
						OtakuTalk
					</div>
				</Slide>
				<Slide direction='right' duration={1500} triggerOnce={false} className="full-width">
					<div className="otakutalk-subtitle">
						Interactive voice chat with anime characters
					</div>
				</Slide>

				<Slide direction='up' duration={1500} triggerOnce={false} className="full-width otakutalk-descript">
					<div className="row">
					<div className="col s12 m4 center">
						<span className="otakutalk-em">Role</span> Full-stack developer
					</div>
					<div className="col s12 m4 center">
						<span className="otakutalk-em">Context</span> Innovation
					</div>
					<div className="col s12 m4 center">
						<span className="otakutalk-em">Period</span> Summer 2024
					</div>
					</div>
				</Slide>
			</div>
			<div className="otakutalk-intro">
				<div className="otakutalk-intro-container">
					<div className="otakutalk-intro-title"
						data-aos="flip-up"
						data-aos-delay="200"
					>
						Introduction
					</div>
					<div className="otakutalk-intro-content"
						data-aos="fade-up"
						data-aos-delay="200"
					>
						OtakuTalk is an innovative platform that lets you interact with your favorite <span className="amber-text">anime-style characters</span> through voice. Combining advanced <span className="amber-text">text-to-speech</span>, <span className="amber-text">speech-to-text</span>, <span className="amber-text">animation</span>, and <span className="amber-text">lip-sync technologies</span>, it delivers an immersive and engaging experience for <span className="amber-text">anime enthusiasts</span>.
					</div>
					<div className="center"
						data-aos="flip-right"
						data-aos-delay="300"
					>
						<a className="pulse-btn otakutalk-intro-streamlit"
							href="https://github.com/minhanh29/interactive-anime-chatbot"
							target="_blank"
							onClick={() => logEvent(analytics, "otakutalk_open_github")}
						>
							View on GitHub
						</a>
					</div>
				</div>
			</div>

			<div className='otakutalk-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					INFORMATION & ANALYSIS
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Project Details
				</div>
			</div>

			<div className="row otakutalk-section-container">
				<div className="col l5 m12"
					data-aos="fade-down"
					data-aos-delay="200"
				>
					<div className="section-slogan">
						Voice-Activated Anime Interaction
					</div>
					<div className="section-slogan-divider-left"></div>
					<div className="section-content">
						OtakuTalk lets you engage with <span className="otakutalk-em-2">anime-style characters</span> through voice in real time. With advanced technologies like <span className="otakutalk-em-2">text-to-speech</span>, <span className="otakutalk-em-2">lip sync</span>, and <span className="otakutalk-em-2">animated 2D visuals</span>, it brings characters to life for <span className="otakutalk-em-2">anime enthusiasts</span>.<br/><br/>
						Enjoy seamless interactions with <span className="otakutalk-em-2">pseudo-planning, real-time speech</span>, <span className="otakutalk-em-2">animation</span>, and <span className="otakutalk-em-2">voice activity detection</span>. OtakuTalk <span className='otakutalk-em-2'>learns continually</span> and allows <span className="otakutalk-em-2">interruptions</span>, ensuring natural and engaging conversations every time.
					</div>
				</div>
				<div className="col l7 m12 otakutalk-img-container"
					data-aos="fade-up"
					data-aos-delay="200"
				>
					<img className="otakutalk-section-img" src={otakutalk1} alt="OtakuTalk Interface" />
				</div>
			</div>

			<div className='otakutalk-title-container'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					TECHNICAL INFORMATION
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Technology
				</div>
			</div>

			<div className="row otakutalk-section-container"
				data-aos="fade-up"
				data-aos-delay="200"
			>
				<div className="col l3 m6 s12 center">
					<a href="https://onnx.ai/" target="_blank">
						<img className="otakutalk-logo-img height-90" src={onnx_icon} alt="PyTorch icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://opencv.org/" target="_blank">
						<img className="otakutalk-logo-img height-70" src={opencv_icon} alt="opencv icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://www.postgresql.org/" target="_blank">
						<img className="otakutalk-logo-img height-90" src={postgresql_icon} alt="mediapipe icon" />
					</a>
				</div>
				<div className="col l3 m6 s12 center">
					<a href="https://cloud.google.com/speech-to-text" target="_blank">
						<img className="otakutalk-logo-img height-90" src={google_s2t_icon} alt="streamlit icon" />
					</a>
				</div>
			</div>

			<div className='otakutalk-title-container no-margin-top'>
				<div
					className='section-sub-title'
					data-aos="flip-down"
					data-aos-delay="200"
				>
					SHOWTIME
				</div>
				<div
					className='section-title'
					data-aos='flip-down'
					data-aos-delay="200"
				>
					Demonstration
				</div>
			</div>

			<div className="center"
					data-aos="fade-down"
					data-aos-delay="200"
			>
				<iframe width={vidWidth} height={vidHeight} src="https://d22sdm4oybjqf2.cloudfront.net/serena_demo.webm" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope;" allowfullscreen></iframe>
			</div>

			<div className="vertical-line"></div>

			<Recommend subtitle="NEXT PROJECT" title="Project YouHand" to="/youhand" />
			<Footer />
		</div>
	)
}


export default OtakuTalk
